import React, { useState } from "react";
import { URL_LINK, getToken } from "../Helper/HelperAPI";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function Register() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setConfirm_Password] = useState();
  const nevigate = useNavigate();
  const LoginHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const AllData = {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      };
      if (!name || !email || !password || !password_confirmation) {
        toast.error("all fields are required...", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return;
      } else if (password !== password_confirmation) {
        toast.error("Password and Confirm Password doesn't match", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return;
      } else {
        fetch(`${URL_LINK}/user/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(AllData),
        })
          .then((res) => res.json())
          .then((result) => {
            let allResult = result.user;
            if (!allResult) {
              toast.error(result.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setTimeout(() => {
                setLoading(false);
              }, 1000);
              return;
            } else {
              toast.success(result.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });

              setTimeout(() => {
                nevigate("/signin");
              }, 3000);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <div className="container p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="login-form-section">
              <form action="" onSubmit={LoginHandler}>
                <div className="input-fields text-light">
                  <h2>BLOGE</h2>
                </div>
                <div className="input-fields text-light">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="abcd"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="abcd@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light">
                  <label>Password</label>
                  <input
                    type="text"
                    placeholder="abcd@123"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    placeholder="abcd@123"
                    onChange={(e) => setConfirm_Password(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light">
                  <button
                    type="submit"
                    className="btn btn-primary btn-user btn-block"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "SignUp"}
                  </button>
                </div>
                <div className="input-fields text-light">
                  {/* <Link to="/signin" className="text-light">
                    Login an Account!
                  </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
