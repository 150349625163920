import React from "react";
import { Oval } from 'react-loader-spinner'

function Loader() {
  return (
    <>
      <h6 className="loading-section">
        <span className="m-1">
          <Oval
            height={18}
            width={18}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </span>
        Please wait ...
      </h6>
    </>
  );
}

export default Loader;
