import React from "react";
import { Route, Routes } from "react-router-dom";
import Blogs from "./Pages/Blogs";
import Category from "./View/Category/Category";
import AddBlog from "./View/Blogs/AddBlog";
import UpdateBlog from "./View/Blogs/UpdateBlog";
import ViewBlog from "./View/Blogs/ViewBlog";
import Loader from "./Helper/Loader";
import Comment from "./View/Comments/Comment";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./Profile/Login";
import Protected from "./Helper/Protected";
import Home from "./Pages/Home";
import Register from "./Profile/Register";
import MailVerify from "./Profile/MailVerify";
import Verify from "./Profile/VerifyEmail";
import PageNotFound from "./Helper/PageNotFound";
import Ads from "./View/Ads/Ads";
import Media from "./View/Media/Media";
import AllContact from "./View/Contact/AllContact";
import AdminUser from "./View/AdminUser/AdminUser";

function App() {
  return (
    <>
      <Routes>
        <Route path="/signin" element={<Protected ProtectRoute={Login} />} />
        <Route path="/signup" element={<Protected ProtectRoute={Register} />} />

        <Route path="/" element={<Protected ProtectRoute={Home} />} />

        <Route path="/blogs" element={<Protected ProtectRoute={Blogs} />} />
        <Route
          path="/add-new-blog"
          element={<Protected ProtectRoute={AddBlog} />}
        />
        <Route
          path="/updated-blog/:id"
          element={<Protected ProtectRoute={UpdateBlog} />}
        />
        <Route
          path="/view-blog/:id"
          element={<Protected ProtectRoute={ViewBlog} />}
        />

        <Route
          path="/blog-comment"
          element={<Protected ProtectRoute={Comment} />}
        />

        <Route
          path="/contact"
          element={<Protected ProtectRoute={AllContact} />}
        />

        <Route
          path="/admin-user"
          element={<Protected ProtectRoute={AdminUser} />}
        />

        <Route path="/ads" element={<Protected ProtectRoute={Ads} />} />
        <Route path="/media" element={<Protected ProtectRoute={Media} />} />

        <Route
          path="/category"
          element={<Protected ProtectRoute={Category} />}
        />
        <Route path="/email-verify" element={<Verify />} />

        <Route path="/loading" element={<Loader />} />

        <Route path="/profile/email-verify" element={<MailVerify />} />

        <Route path="/404-error" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
