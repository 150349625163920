import React, { useEffect, useState } from "react";
import ProductHeading from "../../Components/ProductHeading";
import { FiCheckCircle, FiTruck } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { URL_LINK } from "../../Helper/HelperAPI";

function ViewBlog() {
  const params = useParams();
  const [data, setData] = useState([]);
  const {
    _id,
    title,
    image,
    category,
    status,
  } = data;

  const getBlogData = async () => {
    let response = await fetch(`${URL_LINK}/blog/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    let final_result = result.blog;
    setData(final_result);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  const body = data.description;


  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 mb-5">
            <ProductHeading
              heading="Blog is Live"
              link="/blogs"
              title="Go Back"
            />
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 col-12">
            <div className="view-image">
              <img
                src={image}
                alt="livyalife"
              />
              <div className="updated-product">
                <Link to={`/updated-blog/${params.id}`} type="button">
                  Edit Blog
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 col-12">
            <div className="product-content-review">
              <div className="product-content text-center">
                <h4>Lorem ipsum dolor sit amet consectetur adipisicing</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum,
                  similique.
                </p>
                <h6>
                  <s style={{ color: "gray", marginRight: "10px" }}> ₹ 123</s>{" "}
                  <span>203</span>
                </h6>
                <h6>Upto 40% OFF</h6>
                <div className="store-content d-flex justify-content-evenly">
                  <span>
                    <FiCheckCircle /> Active
                  </span>
                  <span className="client-Product">
                    <FiTruck /> Amazon
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 col-12 mt-4">
            <div className="product-content-review product-he">
              <div className="product-content text-center">
                <h3 className="mb-4">{title}</h3>
                <div className="store-content mb-3 d-flex justify-content-start">
                  <span>
                    <FiCheckCircle /> {status}
                  </span>
                  <span className="client-Product">
                    <FiTruck /> {category?.title}
                  </span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBlog;
