import React from "react";
import { FiCopy, FiTrash2 } from "react-icons/fi";
import {
  useDeleteMediaMutation,
  useGetMediaQuery,
} from "../../App/Slice/mediaSlice";
import { toast } from "react-toastify";
import AddMedia from "./AddMedia";
import Navbar from "../../Components/Navbar";
import { FILE_URL } from "../../Helper/HelperAPI";

function Media() {

  const { data } = useGetMediaQuery();
  const media = data?.Media;
  

  const [deleteMedia] = useDeleteMediaMutation();

  const DeleteHandler = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to delete?");
    if (userConfirmed) {
      const result = await deleteMedia(id);
      const message = result?.data;

      if (message?.status === "failed") {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });
      } else {
        toast.success(message?.message, {
          position: "top-right",
          autoClose: 1000,
          theme: "colored",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid Dashboard-Round">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="product-header d-flex justify-content-start align-items-center">
              <h4>All Media</h4>
              <button
                data-bs-toggle="modal"
                href="#exampleModalToggleMedia"
                role="button"
              >
                Add New Ads
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="col_out_side mt-3">
              <div className="row">
                {media?.map((val, ind) => {
                  const { _id, image } = val;
                  {/* console.log('image', `${FILE_URL}/${image}`) */}
                  return (
                    <div key={ind} className="col-2 mb-4">
                      <div className="media_image">
                        <img src={`${FILE_URL}/${image}`} alt="blozee" />
                        <div className="overflow_style">
                          <span
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${FILE_URL}/${val?.image}`
                              );
                              alert("copy url link");
                            }}
                          >
                            <FiCopy />
                          </span>
                          <span onClick={() => DeleteHandler(val?._id)}>
                            <FiTrash2 />
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddMedia />
    </>
  );
}

export default Media;
