import React from "react";
import {
  FiGrid,
  FiImage,
  FiLogOut,
  FiMonitor,
  FiPhoneCall,
  FiServer,
  FiTv,
  FiUser,
} from "react-icons/fi";
import { IoMdArrowDropright } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const nevigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("admin_user"));
  // console.log(user);
  const LougoutHandler = () => {
    localStorage.clear();
    nevigate("/signin");
  };
  return (
    <>
      <div className="container-fluid navbar-section">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-dark mb-3">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img src="../../assets/image/logo.svg" alt="Blozee" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="/"
                      >
                        <span>
                          <FiGrid />
                        </span>{" "}
                        Dashboard
                      </Link>
                    </li>
                    {/* Blozee-Platfrom */}
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <FiServer />
                        </span>{" "}
                        Platform
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link className="dropdown-item" to="/category">
                            <span>
                              <IoMdArrowDropright />
                            </span>
                            Category
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/blogs">
                            <span>
                              <IoMdArrowDropright />
                            </span>{" "}
                            Blogs
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/blog-comment">
                            <span>
                              <IoMdArrowDropright />
                            </span>{" "}
                            Blog Comments
                          </Link>
                        </li>
                        <li></li>
                      </ul>
                    </li>
                    {/* ads-platform */}
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          <FiMonitor />
                        </span>{" "}
                        Features
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link className="dropdown-item" to="/ads">
                            <span>
                              <FiTv />
                            </span>{" "}
                            Livya Ads
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/contact">
                            <span>
                              <FiPhoneCall />
                            </span>{" "}
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/admin-user">
                            <span>
                              <FiPhoneCall />
                            </span>{" "}
                            Admin User
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/media">
                        <span>
                          <FiImage />
                        </span>{" "}
                        Media
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="btn-group-profile">
                    <FiUser />
                  </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <p className="text-light p-2">👋 {user && user.name}</p>
                  <li>
                    <Link className="dropdown-item" to="/signup">
                      New User Register
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item logout-btn bg-danger mb-0 font-light"
                      onClick={LougoutHandler}
                    >
                      <span>
                        <FiLogOut />
                      </span>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
