import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK, getToken } from "../../Helper/HelperAPI";

export const adminSlice = createApi({
  reducerPath: "adminSlice",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getAdminUser: builder.query({
      query: () => {
        return {
          url: "/user/all-admin",
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        };
      },
    }),

    deleteAdminUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/user/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        };
      },
    }),

  }),
});

export const {
  useGetAdminUserQuery,
  useDeleteAdminUserMutation
} = adminSlice;
