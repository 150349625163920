import React from "react";
import { Link } from "react-router-dom";

function ProductHeading(props) {
  return (
    <>
      <div className="product-header d-flex justify-content-start align-items-center">
        <h4>{props.heading}</h4>
        <Link to={props.link} type="button">
          {props.title}
        </Link>
      </div>
    </>
  );
}

export default ProductHeading;
