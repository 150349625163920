import React from "react";
import Header from "../View/Home/Header";
import DashNotify from "../View/Home/DashNotify";
import Navbar from "../Components/Navbar";
import { URL_LINK, getToken } from "../Helper/HelperAPI";
import { useEffect } from "react";

function Home() {
  const getData = () => {
    try {
      fetch(`${URL_LINK}/user/all-admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let resut_data = result.message;
          if (resut_data === "Not Authorized") {
            localStorage.clear();
            window.location.href = "/signin";
          } else {
            return;
          }
        });
    } catch (error) {
      if (error) {
        localStorage.clear();
        window.location.href = "/signin";
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Navbar />
      <Header />
      <DashNotify />
    </>
  );
}

export default Home;
