import React from "react";

function DashNotify() {
  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row mb-3">
          <div className="col-lg-5 col-12">
            <div className="notify-section"></div>
          </div>
          <div className="col-lg-7 col-12">
            <div className="notify-section"></div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-6 col-12">
            <div className="notify-section"></div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="notify-section"></div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4 col-12">
            <div className="notify-section"></div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="notify-section"></div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="notify-section"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashNotify;
