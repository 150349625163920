import React from 'react'
import Navbar from '../../Components/Navbar';
import { useGetContactQuery } from '../../App/Slice/contactSlice';

function AllContact() {
    const { data, isLoading } = useGetContactQuery();

    return (
        <>
            <Navbar />
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <div className="publisher-all d-flex justify-content-start">
                            <h5>
                                All
                                {/* <span>{data?.blogComment_lenght}</span> */}
                            </h5>
                            <h5>
                                Published <span>2</span>
                            </h5>
                        </div>
                        <div className="product-search">
                            <form action="">
                                <input type="text" placeholder="Product Search" />
                            </form>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table_section mt-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Full Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.contact?.map((ele, ind) => {
                                            return (
                                                <tr key={ind}>
                                                    <th scope="row">{ind + 1}</th>
                                                    <td>{ele?.name}</td>
                                                    <td>{ele?.phone}</td>
                                                    <td>{ele?.email}</td>
                                                    <td>{ele?.subject}</td>
                                                    <td>{ele?.message}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllContact