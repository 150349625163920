import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { blogs } from "./Slice/BlogSlice";
import { category } from "./Slice/CategorySlice";
import { subCategory } from "./Slice/SubCategorySlice";
import { ads } from "./Slice/AdsSlice";
import { media } from "./Slice/mediaSlice";
import { contactSlice } from "./Slice/contactSlice";
import { adminSlice } from "./Slice/adminSlice";

const store = configureStore({
  reducer: {
    [blogs.reducerPath]: blogs.reducer,
    [category.reducerPath]: category.reducer,
    [subCategory.reducerPath]: subCategory.reducer,
    [ads.reducerPath]: ads.reducer,
    [media.reducerPath]: media.reducer,
    [contactSlice.reducerPath]: contactSlice.reducer,
    [adminSlice.reducerPath]: adminSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      blogs.middleware,
      category.middleware,
      subCategory.middleware,
      ads.middleware,
      media.middleware,
      contactSlice.middleware,
      adminSlice.middleware,
    ]),
});

setupListeners(store.dispatch);
export default store;
