import React, { useEffect, useState } from "react";
import {
  FiCheckCircle,
  FiEdit,
  FiEye,
  FiMinusCircle,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";
import Loader from "../../Helper/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../Components/Navbar";
import {
  useDeleteCategoryMutation,
  useGetCategoryQuery,
} from "../../App/Slice/CategorySlice";
import {
  useDeleteSubCategoryMutation,
  useGetSubCategoryQuery,
} from "../../App/Slice/SubCategorySlice";

function Category() {
  const [updateId, setUpdateId] = useState();
  const [updateCId, setUpdateCId] = useState();

  const { data: categoryDatas, isLoading } = useGetCategoryQuery();
  const loading = isLoading?.isLoading;
  const category = categoryDatas?.category;

  const { data } = useGetSubCategoryQuery();
  const subCategory = data?.category;

  const [deleteCategory] = useDeleteCategoryMutation();

  const deleteMainCategoryHandler = async (id) => {
    const result = await deleteCategory(id);
    let mes = result?.data?.status;
    if (mes === "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const UpdateHandler = (id) => {
    setUpdateId(id);
  };

  const UpdateCategoryHandler = (id) => {
    setUpdateCId(id);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="product-header d-flex justify-content-start align-items-center">
              <h4>All Category</h4>
              <button
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                role="button"
              >
                Add Cateogry
              </button>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="publisher-all d-flex justify-content-start">
              <h5>
                All <span>{category?.category_lenght}</span>
              </h5>
              <h5>
                Published <span></span>
              </h5>
            </div>
            <div className="product-search">
              <form action="">
                <input type="text" placeholder="Product Search" />
              </form>
            </div>
          </div>
          <div className="col-12 mt-4">
            {loading ? (
              <Loader />
            ) : (
              <div className="store-grid">
                {category?.map((val, ind) => {
                  return (
                    <div key={ind} className="grid-section">
                      <div className="card-img category">
                        <img src={val.image} alt="livyalife" />
                        <div className="dropdown">
                          <span
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </span>
                          <ul
                            className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton2"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => deleteMainCategoryHandler(val._id)}
                              >
                                <FiTrash2 />
                              </button>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                to="#exampleModalTogglesMain"
                                role="button"
                                onClick={() => UpdateCategoryHandler(val._id)}
                              >
                                {" "}
                                <FiEdit />
                              </Link>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                <FiEye />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="store-content">
                        <h4 className="text-center">{val.title}</h4>
                        <div className="d-flex justify-content-between">
                          {val.status === "Pending" ? (
                            <span className="not-active">
                              {" "}
                              <FiMinusCircle /> In Active
                            </span>
                          ) : (
                            <span>
                              <FiCheckCircle /> Active
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <AddCategory />
      <UpdateCategory updateId={updateCId} />
    </>
  );
}

export default Category;
