import React from "react";
import { FiCodepen, FiEdit3, FiLayout } from "react-icons/fi";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="container-fluid p-0 Header-section">
        <div className="row">
          <div className="col-12">
            <div className="header-image">
              <img
                src="https://img.freepik.com/free-photo/cosmetics-product-advertising-stand-exhibition-wooden-podium-green-background-with-leaves-sha_1258-170079.jpg?size=626&ext=jpg&ga=GA1.2.2031260379.1686738568&semt=sph"
                alt="livyalife"
              />
              <span>
                <h2>Welcome to BloGGer!</h2>
                <Link to="https://livyalife.com">Learn more about the livyalife</Link>
              </span>
            </div>
          </div>
        </div>
        <div className="row bg-light m-0">
          <div className="col-4">
            <div className="header-grid-content d-flex justify-content-start">
                <div className="header-icon">
                    <span><FiEdit3 /></span>
                </div>
                <div className="grid-content">
                    <h4>Author rich content with blocks and patterns</h4>
                    <p>Block patterns are pre-configured block layouts. Use them to get inspired or create new pages in a flash.</p>
                    <Link to="">Add new pages</Link>
                </div>
            </div>
          </div>
          <div className="col-4">
            <div className="header-grid-content d-flex justify-content-start">
                <div className="header-icon">
                    <span><FiCodepen /></span>
                </div>
                <div className="grid-content">
                    <h4>Start Customizing</h4>
                    <p>Configure your site’s logo, header, menus, and more in the Customizer.</p>
                    <Link to="">Open the Customizer</Link>
                </div>
            </div>
          </div>
          <div className="col-4">
            <div className="header-grid-content d-flex justify-content-start">
                <div className="header-icon">
                    <span><FiLayout /></span>
                </div>
                <div className="grid-content">
                    <h4>Discover a new way to build your site.</h4>
                    <p>There is a new kind of WordPress theme, called a block theme, that lets you build the site you’ve always wanted — with blocks and styles.</p>
                    <Link to="">Learn about block themes</Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
