import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK, getToken } from "../../Helper/HelperAPI";

export const subCategory = createApi({
  reducerPath: "subCategory",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getSubCategory: builder.query({
      query: () => {
        return {
          url: "/sub-category",
          method: "GET",
        };
      },
    }),

    searchCategory: builder.query({
      query: (key) => {
        console.log("key", key);
        return {
          url: `/category/search/${key}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    getByIdSubCategory: builder.query({
      query: (id) => {
        return {
          url: `/sub-category/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    deleteSubCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/sub-category/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    UpdateSubCategory: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/sub-category/${id}`,
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    createSubCategory: builder.mutation({
      query: (body) => {
        return {
          url: "/sub-category",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSubCategoryQuery,
  useDeleteSubCategoryMutation,
  useCreateSubCategoryMutation,
  useUpdateSubCategoryMutation,
  useGetByIdSubCategoryQuery,
  useSearchCategoryQuery,
} = subCategory;
