import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK, getToken } from "../../Helper/HelperAPI";

export const blogs = createApi({
  reducerPath: "blogs",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    createBlogs: builder.mutation({
      query: (body) => {
        return {
          url: "/blog",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    updateBlogs: builder.mutation({
      query: (body) => {
        console.log("body :", body);
        return {
          url: `/blog/${body.ids}`,
          method: "PUT",
          body: body.data,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    getByIdBlogs: builder.query({
      query: (id) => {
        return {
          url: `/blog/id/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    getBlogs: builder.query({
      query: () => {
        return {
          url: "/blog",
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    deleteBlogs: builder.mutation({
      query: (id) => {
        return {
          url: `/blog/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateBlogsMutation,
  useGetBlogsQuery,
  useDeleteBlogsMutation,
  useGetByIdBlogsQuery,
  useUpdateBlogsMutation
} = blogs;
