import React, { useEffect, useState } from "react";
import {
  FiCheckCircle,
  FiCornerDownRight,
  FiEdit,
  FiEye,
  FiMessageSquare,
  FiMinusCircle,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { URL_LINK, getToken } from "../../Helper/HelperAPI";
import Loader from "../../Helper/Loader";
import { toast } from "react-toastify";
import Navbar from "../../Components/Navbar";

function Comment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const commentData = async () => {
    setLoading(true);
    let response = await fetch(`${URL_LINK}/blog-comment`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    setData(result);
    setLoading(false);
  };

  const deletehandler = async (id) => {
    setLoading(true);
    let response = await fetch(`${URL_LINK}/blog-comment/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    let result = await response.json();
    let mes = result.status;
    if (mes == "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    commentData();
    setLoading(false);
  };

  useEffect(() => {
    commentData();
  }, []);

  return (
    <>
    <Navbar />
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 mb-5">
            <div className="product-header d-flex justify-content-start align-items-center">
              <h4>All Blog Comments</h4>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="publisher-all d-flex justify-content-start">
              <h5>
                All <span>{data?.blogComment_lenght}</span>
              </h5>
              <h5>
                Published <span>2</span>
              </h5>
            </div>
            <div className="product-search">
              <form action="">
                <input type="text" placeholder="Product Search" />
              </form>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {data.blogComment?.map((val, ind) => {
                return (
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="comment-section card-img">
                      <div className="dropdown">
                        <span
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <FiMoreVertical />
                        </span>
                        <ul
                          className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                          aria-labelledby="dropdownMenuButton2">
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => deletehandler(val._id)}>
                              <FiTrash2 />
                            </button>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              to="#exampleModalToggles"
                              role="button">
                              {" "}
                              <FiEdit />
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <FiEye />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="comment-bac p-2">
                        <h6>{val.email}</h6>
                        <div
                          className="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingOne">
                          <div className="accordion-body">
                            <h5 className="d-flex justify-content-start">
                              <span>
                                <FiCornerDownRight /> Blog :
                              </span>{" "}
                              <p>{val.blog?.title}</p>
                            </h5>
                            <h5 className="d-flex justify-content-start">
                              <span>
                                <FiMessageSquare /> Comment :
                              </span>{" "}
                              <p>{val.message}</p>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Comment;
