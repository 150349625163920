import React from "react";
import ProductHeading from "../../Components/ProductHeading";
import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCreateBlogsMutation } from "../../App/Slice/BlogSlice";
import { useGetCategoryQuery } from "../../App/Slice/CategorySlice";

function AddBlog() {
  const [title, setTitle] = useState();
  const [short_desc, setShort_desc] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState();

  const [tags, setTag] = useState();

  const { data: mainCate } = useGetCategoryQuery();
  const mainCategory = mainCate?.category;

  const [createBlogs, isLoading] = useCreateBlogsMutation();
  const loading = isLoading?.isLoading;

  // tag
  const [tagData, setTagData] = useState([]);

  const tagHandler = () => {
    const tag = tags;
    if (!tag) {
      return;
    }
    setTagData([...tagData, tag]);
  };

  //keyword Array Delete
  const tagDeleteHandler = (id) => {
    const deleteKeyword = tagData.filter((ele, ind) => {
      return ind !== id;
    });
    setTagData(deleteKeyword);
  };

  const blogSubmitHandler = async (e) => {
    e.preventDefault();

    if (!title || !short_desc || !image || !category || !description) {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      return;
    }

    let data = {
      title: title,
      short_desc: short_desc,
      image: image,
      category: category,
      description: description,
      tags: tagData,
    };
    const result = await createBlogs(data);
    let mes = result?.data?.status;
    if (mes === "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      setTimeout(() => {
        window.location.href = "/blogs";
      }, 1000);
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 mb-5">
            <ProductHeading
              heading="Add New Blog"
              link="/blogs"
              title="Go Back"
            />
          </div>
        </div>
        <form action="" onSubmit={blogSubmitHandler}>
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="add-product">
                <div className="container contact-form">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-view-contact">
                        <div className="form-view">
                          <div className="form-d">
                            <div className="input-fields input-title">
                              <label>Title</label>
                              <input
                                type="text"
                                name="name"
                                placeholder="Lorem"
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>

                            <div className="input-fields input-title">
                              <label>Short Description</label>
                              <input
                                type="text"
                                name="name"
                                placeholder="Lorem"
                                onChange={(e) => setShort_desc(e.target.value)}
                              />
                            </div>
                            <div className="input-fields main-input mb-0">
                              <label>Product Description & Features</label>
                              <Editor
                                initialValue="write a blog..."
                                onEditorChange={(newText) =>
                                  setDescription(newText)
                                }
                                init={{
                                  height: 600,
                                  plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code | help",
                                  image_title: true,
                                  automatic_uploads: true,
                                  file_picker_types: "image",
                                  file_picker_callback: function (
                                    callback,
                                    value,
                                    meta
                                  ) {
                                    if (meta.filetype === "image") {
                                      const input =
                                        document.createElement("input");
                                      input.setAttribute("type", "file");
                                      input.setAttribute(
                                        "accept",
                                        "https://livyalife.com/public/uploads/*"
                                      );
                                      input.onchange = function () {
                                        const file = this.files[0];
                                        const reader = new FileReader();
                                        reader.onload = function () {
                                          const id =
                                            "blobid" + new Date().getTime();
                                          const blobCache =
                                            window.tinymce.activeEditor
                                              .editorUpload.blobCache;
                                          const base64 =
                                            reader.result.split(",")[1];
                                          const blobInfo = blobCache.create(
                                            id,
                                            file,
                                            base64
                                          );
                                          blobCache.add(blobInfo);
                                          callback(blobInfo.blobUri(), {
                                            title: file.name,
                                          });
                                        };
                                        reader.readAsDataURL(file);
                                      };
                                      input.click();
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="add-product p-2 mb-4">
                <div className="add-header">
                  <h5>Publish</h5>
                </div>
                <div className="published-btn d-flex justify-content-end">
                  <button type="submit" disabled={loading}>
                    {loading ? "Please Wait.." : "Publish"}
                  </button>
                </div>
                <div className="input-fields">
                  <label>Category</label>
                  <select
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="Select Category" disabled selected>
                      Select Category
                    </option>
                    {mainCategory?.map((val, ind) => {
                      return (
                        <option key={ind} value={val._id}>
                          {val.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="add-product p-2 mb-4">
                <h5 className="mb-2">Image Uploads</h5>
                <div className="product-image">
                  {image ? (
                    <img src={image} alt="livyalife" />
                  ) : (
                    <img
                      src="https://img.freepik.com/free-vector/gradient-galaxy-background_23-2148983655.jpg?size=626&ext=jpg&ga=GA1.1.2031260379.1686738568&semt=sph"
                      alt="livyalife"
                    />
                  )}
                </div>
                <div className="input-fields mt-2 mb-0">
                  <input
                    type="text"
                    name="name"
                    placeholder="url"
                    onChange={(e) => setImage(e.target.value)}
                  />
                </div>
              </div>
              <div className="add-product p-2">
                <h5 className="mb-2">Tags</h5>
                <div className="input-fields tags-btn mt-2">
                  <input
                    type="text"
                    name="name"
                    placeholder="#Food"
                    onChange={(e) => setTag(e.target.value)}
                  />
                  <div className="published-btn">
                    <button type="button" onClick={tagHandler}>
                      Add
                    </button>
                  </div>
                </div>
                <div className="tags-result">
                  <ul className="mb-0">
                    {tagData &&
                      tagData?.map((val, ind) => {
                        return <li key={ind}># {val} <span onClick={() => tagDeleteHandler(ind)} style={{cursor:'pointer', marginLeft:10}}>X</span></li>;
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddBlog;
