import React from 'react'
import Navbar from '../../Components/Navbar';
import { toast } from "react-toastify";
import { useDeleteAdminUserMutation, useGetAdminUserQuery } from '../../App/Slice/adminSlice';

function AdminUser() {
    const { data, isLoading } = useGetAdminUserQuery();

    const [deleteUser] = useDeleteAdminUserMutation()

    const deleteHanlder = async (id) => {
        const userConfirmed = window.confirm("Are you sure deleted user!");
        if (userConfirmed) {
            const result = await deleteUser({ id })
            const message = result?.data;
            if (message?.status === "success") {
                toast.success("Deleted Successfully", {
                    position: "top-right",
                    autoClose: 1000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toast.error(result?.message, {
                    position: "top-right",
                    autoClose: 1000,
                    theme: "colored",
                });
                return;
            }
        }
        return
    }
    return (
        <>
            <Navbar />
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <div className="publisher-all d-flex justify-content-start">
                            <h5>
                                All
                                {/* <span>{data?.blogComment_lenght}</span> */}
                            </h5>
                            <h5>
                                Published <span>2</span>
                            </h5>
                        </div>
                        <div className="product-search">
                            <form action="">
                                <input type="text" placeholder="Product Search" />
                            </form>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="table_section mt-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">E-Mail</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">E-mail Verify</th>
                                        <th scope="col">Other</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.users?.map((ele, ind) => {
                                            return (
                                                <tr key={ind}>
                                                    <th scope="row">{ind + 1}</th>
                                                    <td>{ele?.name}</td>
                                                    <td>{ele?.email}</td>
                                                    <td>{ele?.role}</td>
                                                    <td>{ele?.email_verified}</td>
                                                    <td><button className='btn text-danger' onClick={() => deleteHanlder(ele?._id)}><i class="fa-solid fa-trash"></i></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUser