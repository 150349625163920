import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK, getToken } from "../../Helper/HelperAPI";

export const category = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => {
        return {
          url: "/category",
          method: "GET",
        };
      },
    }),

    getByIdCategory: builder.query({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    deleteCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    UpdateCategory: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/category/${id}`,
          method: "PUT",
          body: data,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),

    createCategory: builder.mutation({
      query: (body) => {
        return {
          url: "/category",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useGetCategoryQuery,
  useDeleteCategoryMutation,
  useGetByIdCategoryQuery,
  useUpdateCategoryMutation
} = category;
