import React, { useEffect, useState } from "react";
import Loader from "../../Helper/Loader";
import { toast } from "react-toastify";
import { useGetByIdCategoryQuery, useUpdateCategoryMutation } from "../../App/Slice/CategorySlice";

function UpdateCategory(updateId) {
  const params = updateId.updateId;

  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [status, setStatus] = useState();

  const { data, isLoading: dataLoading } = useGetByIdCategoryQuery(params);
  const loading = dataLoading?.isLoading;
  const subCate = data?.category || {};

  const [updatedCategory, isLoading] = useUpdateCategoryMutation();
  const updateLoad = isLoading?.isLoading;

  useEffect(() => {
    const { title, image, status } = subCate;
    setTitle(title);
    setImage(image);
    setStatus(status);
  }, [subCate]);

  const categoryUpdateSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      title: title,
      image: image,
      status: status,
    };
    try {
      const result = await updatedCategory({ id: params, data: data });
      console.log("result : ", result);
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalTogglesMain"
        aria-hidden="true"
        aria-labelledby="exampleModalTogglesMainLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalTogglesMainLabel">
                Updated Category
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <Loader />
              ) : (
                <div className="form-view">
                  <form
                    action=""
                    className="p-2"
                    onSubmit={categoryUpdateSubmitHandler}
                  >
                    <div className="input-fields main-input client-logo-image mb-5">
                      {image ? (
                        <img src={image} alt="livyalife" />
                      ) : (
                        <img
                          src="https://img.freepik.com/free-photo/gourmet-chicken-biryani-with-steamed-basmati-rice-generated-by-ai_188544-13480.jpg?size=626&ext=jpg&ga=GA1.1.2031260379.1686738568&semt=sph"
                          alt="livyalife"
                        />
                      )}
                    </div>
                    <div className="input-fields">
                      <label>Category Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Lorem"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="input-fields">
                      <label>Category Status</label>
                      <select
                        name=""
                        id=""
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="" selected disabled>
                          {status}
                        </option>
                        <option value="Approve">Approve</option>
                        <option value="Pending">Pending</option>
                      </select>
                    </div>
                    <div className="input-fields main-input">
                      <label>Media Uploads</label>
                      <input
                        type="text"
                        name="name"
                        value={image}
                        placeholder="https://livyalife.com/tracker/cou9403940/etc."
                        onChange={(e) => setImage(e.target.value)}
                      />
                    </div>
                    <div className="published-btn">
                      {updateLoad ? (
                        <button
                          type="submit"
                          disabled
                          style={{ background: "gray" }}
                        >
                          Please wait...
                        </button>
                      ) : (
                        <button type="submit">Update Publish</button>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCategory;
