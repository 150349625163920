import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL_LINK } from "../Helper/HelperAPI";

function MailVerify() {
  const nevigate = useNavigate();
  const [mess, setMess] = useState("");
  const currentUrl = window.location.href;
  const params = currentUrl.slice(46, 70);
  const getData = async () => {
    try {
      const result = await fetch(
        `${URL_LINK}/user/email-verify-user?id=${params}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await result.json();
      setMess(res);
    } catch (error) {
      console.log(`email verify error: ${error.message}`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const ClearCookies = () => {
    localStorage.clear();
    nevigate("/signin");
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center row mx-auto">
          <div className="col-lg-12 col-md-12 col-12 mt-5">
            {mess.status !== "failed" ? (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068693234success.png"
                  alt="cashdost-success-mail-verify"
                />
              </div>
            ) : (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068340753cross.png"
                  alt="cashdost-wrong-verify"
                />
              </div>
            )}

            <p className="lead">{mess.message}</p>
            {mess.status !== "failed" ? (
              <div className="signup-btn">
                <button type="button" className="btn" onClick={ClearCookies}>
                  Verify Your E-mail
                </button>
              </div>
            ) : (
              <div className="signup-btn">
                <button
                  type="button"
                  className="btn"
                  disabled
                  style={{ backgroundColor: "#e0e0e0" }}>
                  Verify your E-mail
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MailVerify;
