import React, { useState } from "react";
import {
  FiCheckCircle,
  FiEdit,
  FiEye,
  FiMinusCircle,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";
import Loader from "../../Helper/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../Components/Navbar";
import UpdateAds from "./UpdateAds";
import AddAds from "./AddAds";
import { useDeleteAdsMutation, useGetAdsQuery } from "../../App/Slice/AdsSlice";

function Ads() {
  const [updateId, setUpdateId] = useState();
  const { data, isLoading } = useGetAdsQuery();
  const ads = data?.ads;
  const loading = isLoading?.isLoading;

  const [deleteCategory] = useDeleteAdsMutation();

  const deleteMainAdsHandler = async (id) => {
    const result = await deleteCategory(id);
    let mes = result?.data?.status;
    if (mes === "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
    }
  };

  const UpdateAdsHandler = (id) => {
    setUpdateId(id);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="product-header d-flex justify-content-start align-items-center">
              <h4>All Category</h4>
              <button
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                role="button"
              >
                Add New Ads
              </button>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="publisher-all d-flex justify-content-start">
              <h5>
                All <span>{ads?.ads_lenght}</span>
              </h5>
              <h5>
                Published <span></span>
              </h5>
            </div>
            <div className="product-search">
              <form action="">
                <input type="text" placeholder="Product Search" />
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul className="main_category">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="store-grid">
                    {ads?.map((val, ind) => {
                      return (
                        <div key={ind} className="grid-section">
                          <div className="card-img category">
                            <img src={val?.image} alt="Blozee" />
                            <div className="dropdown">
                              <span
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <FiMoreVertical />
                              </span>
                              <ul
                                className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      deleteMainAdsHandler(val?._id)
                                    }
                                  >
                                    <FiTrash2 />
                                  </button>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    to="#exampleModalTogglesUpdate"
                                    role="button"
                                    onClick={() => UpdateAdsHandler(val?._id)}
                                  >
                                    {" "}
                                    <FiEdit />
                                  </Link>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <FiEye />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="store-content">
                            <h4 className="text-center">{val?.title}</h4>
                            <div className="d-flex justify-content-between">
                              {val?.status === "Pending" ? (
                                <span className="not-active">
                                  {" "}
                                  <FiMinusCircle /> In Active
                                </span>
                              ) : (
                                <span>
                                  <FiCheckCircle /> Active
                                </span>
                              )}
                              <span>
                                <p className="mb-0">{val?.ads_style}</p>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------------------- */}
      <AddAds />
      <UpdateAds updateId={updateId} />
    </>
  );
}

export default Ads;
