import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL_LINK } from "../Helper/HelperAPI";

function Verify() {
  const nevigate = useNavigate();
  const [mess, setMess] = useState("");
  const currentUrl = window.location.href;
  const params = currentUrl.slice(44, 70); //il-verify?id=6512ab414ef
  // console.log("currentUrl", params);
//http://localhost:3000/email-verify?id=667d18a950187370c98e27d6
//https://admin.livyalife.com/email-verify?id=667d18a950187370c98e27d6
//https://admin.livyalife.com/email-verify?id=6682a3e5dcc5fbb94d6a7b8c
//6682a3e5dcc5fbb94d6a7b8c
  // let storage = JSON.parse(localStorage.getItem("admin_user"));
  // useEffect(() => {
  //   if (storage) {
  //     return nevigate("/");
  //   } else {
  //     return;
  //   }
  // }, []);

  const getData = async () => {
    try {
      const result = await fetch(`${URL_LINK}/user/email-verify?id=${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await result.json();
      setMess(res);
    } catch (error) {
      console.log(`email verify error: ${error.message}`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const ClearCookies = () => {
    localStorage.clear();
    nevigate("/signin");
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center row mx-auto">
          <div className="col-lg-12 col-md-12 col-12 mt-5">
            {mess.status !== "failed" ? (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068693234success.png"
                  alt="cashdost-success-mail-verify"
                />
              </div>
            ) : (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068340753cross.png"
                  alt="cashdost-wrong-verify"
                />
              </div>
            )}

            <p className="lead">{mess.message}</p>
            {mess.status !== "failed" ? (
              <div className="signup-btn">
                <button
                  type="button"
                  className="btn bg-dark"
                  onClick={ClearCookies}
                >
                  Login Dashboard
                </button>
              </div>
            ) : (
              <div className="signup-btn">
                <button
                  type="button"
                  className="btn"
                  disabled
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  Verify your E-mail
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify;
