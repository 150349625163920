import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCreateAdsMutation } from "../../App/Slice/AdsSlice";
import { useGetCategoryQuery } from "../../App/Slice/CategorySlice";

function AddAds() {
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [category, setCategory] = useState();
  const [link, setLink] = useState();
  const [position, setPosition] = useState();
  const [size, setSize] = useState();

  const { data: subCate } = useGetCategoryQuery();
  const sub_Category = subCate?.category;

  const [createAds, isLoading] = useCreateAdsMutation();
  const loading = isLoading?.isLoading;

  const adsSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const data = {
        title: title,
        image: image,
        category: category,
        link: link,
        position: position,
        ads_style: size,
      };
      const result = await createAds(data);
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalToggleLabel">
                Add New Ads
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-view">
                <form action="" className="p-2" onSubmit={adsSubmitHandler}>
                  <div className="input-fields main-input client-logo-image mb-5">
                    {image ? (
                      <img src={image} alt="livyalife" />
                    ) : (
                      <img
                        src="https://img.freepik.com/free-photo/gourmet-chicken-biryani-with-steamed-basmati-rice-generated-by-ai_188544-13480.jpg?size=626&ext=jpg&ga=GA1.1.2031260379.1686738568&semt=sph"
                        alt="livyalife"
                      />
                    )}
                  </div>
                  <div className="input-fields">
                    <label>Ads Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Ex: Amazon, Myntra"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <label>Category</label>
                    <select
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="Select Category" disabled selected>
                        Select Category
                      </option>
                      {sub_Category?.map((val, ind) => {
                        return (
                          <option key={ind} value={val._id}>
                            {val.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-fields">
                    <label>Ads Size By Position</label>
                    <select
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                    >
                      <option value="Select Position" disabled selected>
                        Select Position
                      </option>
                      <option value="horizontal">horizontal</option>
                      <option value="vertical">vertical</option>
                    </select>
                  </div>
                  <div className="input-fields">
                    <label>Ads Link</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="https://blozee.com/ads/apply"
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </div>
                  <div className="input-fields main-input">
                    <label>Media Uploads</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="https://livyalife.com/tracker/cou9403940/etc."
                      onChange={(e) => setImage(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <label>Ads Set Position</label>
                    <select onClick={(e) => setPosition(e.target.value)}>
                      <option value="select set position" disabled selected>
                        select set position
                      </option>
                      <option value="main_front_1">main_front_1</option>
                      <option value="main_front_2">main_front_2</option>
                      <option value="main_front_3">main_front_3</option>
                      <option value="main_front_4">main_front_4</option>
                      <option value="main_front_5">main_front_5</option>
                      <option value="main_front_6">main_front_6</option>
                      <option value="main_front_7">main_front_7</option>
                      <option value="main_front_8">main_front_8</option>
                      <option value="main_front_9">main_front_9</option>
                      <option value="main_front_10">main_front_10</option>
                      <option value="main_front_11">main_front_11</option>
                      <option value="main_front_12">main_front_12</option>
                      <option value="all_category_13">all_category_13</option>
                      <option value="all_category_14">all_category_14</option>
                      <option value="all_category_15">all_category_15</option>
                      <option value="all_category_16">all_category_16</option>
                      <option value="all_category_17">all_category_17</option>
                      <option value="travel_18">travel_18</option>
                      <option value="travel_19">travel_19</option>
                      <option value="travel_20">travel_20</option>
                      <option value="travel_21">travel_21</option>
                      <option value="travel_22">travel_22</option>
                      <option value="travel_23">travel_23</option>
                      <option value="blog_page_24">blog_page_24</option>
                      <option value="blog_page_25">blog_page_25</option>
                      <option value="blog_page_26">blog_page_26</option>
                      <option value="blog_page_27">blog_page_27</option>
                      <option value="blog_page_28">blog_page_28</option>
                    </select>
                  </div>
                  <div className="published-btn">
                    {loading ? (
                      <button
                        type="submit"
                        disabled
                        style={{ background: "gray" }}
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button type="submit">Publish</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAds;
