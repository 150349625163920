export const URL_LINK = "https://livyalife.com/api";
export const FILE_URL = "https://livyalife.com/public/uploads";


// export const URL_LINK = "http://localhost:8002/api";
// export const FILE_URL = "http://localhost:8002/public/uploads";

export const getToken = () => {
  let user = JSON.parse(localStorage.getItem("admin_user"));
  if (user) {
    return user.token;
  } else {
    return "73j93js857fh589djsjaksj384940DJ34849Djjd";
  }
};
