import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCreateCategoryMutation } from "../../App/Slice/CategorySlice";

function AddCategory() {
  const [title, setTitle] = useState();
  const [image, setImage] = useState();

  const [createCategory] = useCreateCategoryMutation();

  const categorySubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const data = { title: title, image: image };
      const result = await createCategory(data);
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalToggleLabel">
                Add New Category
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-view">
                <form
                  action=""
                  className="p-2"
                  onSubmit={categorySubmitHandler}
                >
                  <div className="input-fields main-input client-logo-image mb-5">
                    {image ? (
                      <img src={image} alt="livyalife" />
                    ) : (
                      <img
                        src="https://img.freepik.com/free-photo/gourmet-chicken-biryani-with-steamed-basmati-rice-generated-by-ai_188544-13480.jpg?size=626&ext=jpg&ga=GA1.1.2031260379.1686738568&semt=sph"
                        alt="livyalife"
                      />
                    )}
                  </div>
                  <div className="input-fields">
                    <label>Category Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Lorem"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="input-fields main-input">
                    <label>Media Uploads</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="https://livyalife.com/tracker/cou9403940/etc."
                      onChange={(e) => setImage(e.target.value)}
                    />
                  </div>
                  <div className="published-btn">
                    <button type="submit">Publish</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategory;
