import React from "react";
import {
  FiCheckCircle,
  FiEdit,
  FiEye,
  FiMoreVertical,
  FiSend,
  FiTrash2,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ProductHeading from "../Components/ProductHeading";
import Loader from "../Helper/Loader";
import { toast } from "react-toastify";
import Navbar from "../Components/Navbar";
import {
  useDeleteBlogsMutation,
  useGetBlogsQuery,
} from "../App/Slice/BlogSlice";

function Blogs() {
  const { data: allBlog, isLoading } = useGetBlogsQuery();
  const data = allBlog?.blog;

  const [deleteBlogs] = useDeleteBlogsMutation();

  const deleteHandler = async (id) => {
    const result = await deleteBlogs(id);
    let mes = result?.data?.status;
    if (mes === "success") {
      toast.success(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(`${mes}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mb-5">
            <ProductHeading
              heading="All Blogs"
              link="/add-new-blog"
              title="Add New"
            />
          </div>
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="publisher-all d-flex justify-content-start">
              <h5>All {<span>{allBlog?.blog_lenght}</span>}</h5>
              <h5>
                Published <span>{}</span>
              </h5>
            </div>
            <div className="product-search">
              <form action="">
                <input type="text" placeholder="Product Search" />
              </form>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="col-12">
              <div className="card-grid">
                {data?.map((val, ind) => {
                  const {
                    _id,
                    title,
                    image,
                    category,
                    status,
                    createdAt,
                    trending,
                  } = val;
                  return (
                    <div key={ind} className="blog-grid">
                      <div className="card-img grid-img">
                        <img src={image} alt="livyalife" />
                        {trending === "Yes" ? (
                          <span className="trending-tags">Trending</span>
                        ) : null}
                        <div className="dropdown">
                          <span
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FiMoreVertical />
                          </span>
                          <ul
                            className="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton2"
                          >
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => deleteHandler(_id)}
                              >
                                <FiTrash2 />
                              </button>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/updated-blog/${_id}`}
                              >
                                <FiEdit />
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/view-blog/${_id}`}
                              >
                                <FiEye />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="product-content text-center">
                        <h4>{title?.slice(0, 70)}</h4>
                        <p>{createdAt?.slice(0, 10)}</p>
                        <div className="store-content d-flex justify-content-between">
                          {status == "Pending" ? (
                            <span className="not-active">
                              <FiCheckCircle /> In Active
                            </span>
                          ) : (
                            <span>
                              <FiCheckCircle /> {status}
                            </span>
                          )}
                          <span className="client-Product">
                            <FiSend /> {category?.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Blogs;
