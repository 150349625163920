import React, { useEffect, useState } from "react";
import { URL_LINK } from "../Helper/HelperAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const nevigate = useNavigate();
  let storage = JSON.parse(localStorage.getItem("admin_user"));
  // console.log(storage);

  useEffect(() => {
    if (storage) {
      return nevigate("/");
    } else {
      return;
    }
  }, []);

  const LoginHandler = (e) => {
    e.preventDefault();
    // alert("ok")
    setLoading(true);
    const AllData = { email: email, password: password };
    if (
      email === "" ||
      (email === "undefined" && password === "") ||
      password === "undefined"
    ) {
      return alert("all fields are required!");
    }
    fetch(`${URL_LINK}/user/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(AllData),
    })
      .then((res) => res.json())
      .then((result) => {
        let res = result?.user;
        if (!res) {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        } else if (res?.role === "normal") {
          toast.error("only login for Admin user", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        } else {
          toast.success(`Hey ${res?.name} Welcome to Bloge Dashboard :)`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          localStorage.setItem("admin_user", JSON.stringify(res));

          setTimeout(() => {
            nevigate("/");
          }, 1000);

          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  return (
    <>
      <div className="container p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="login-form-section">
              <form action="" onSubmit={LoginHandler}>
                <div className="input-fields text-light">
                  <img src="../../assets/image/logo.svg" alt="" />
                </div>
                <div className="input-fields text-light">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="abcd@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="abcd@123"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields text-light mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-user btn-block"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "SignIn"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
